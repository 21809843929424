import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Nav from './components/Nav';
import ProductList from './components/ProductList';
import ProductDetail from './components/ProductDetail';
import ArchiveList from './components/ArchiveList';
import Footer from './components/Footer';
import About from './components/Footer/About';
import FAQ from './components/Footer/FAQ';
import Policies from './components/Footer/Policies';
import ScrollToTop from './components/ScrollToTop';
// import landingPage from './assets/iw-orange-radio.jpg';
import landingPage from './assets/iw-tote.mov';
import './App.css';

function App({ client }) {
  const closed = false;
  const [allProducts, setAllProducts] = useState(null);
  const [archive, setArchive] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const [firstTimeVisitor, setFirstTimeVisitor] = useState(true);
  const [checkoutId, setCheckoutId] = useState(null);
  const [cookies, setCookies, removeCookies] = useCookies([]);
  const [firstTimeOpacity, setFirstTimeOpactity] = useState(1);

  useEffect(() => {
    async function fetchProducts() {
      let homepage = await client.collection.fetchByHandle('frontpage');
      let homepageCollection = await client.collection.fetchWithProducts(homepage.id, { productsFirst : 250 });
      setAllProducts(homepageCollection.products);

      let archive = await client.collection.fetchByHandle('archive');
      let archiveCollection = await client.collection.fetchWithProducts(archive.id, { productsFirst : 250 });
      setArchive(archiveCollection.products);
    }
    fetchProducts();

    let myCartId = cookies['iw-cart'];
    async function createCheckout() {
      let userCheckout = await client.checkout.create();
      setCheckout(userCheckout);
      setCheckoutId(userCheckout.id);
      let date = new Date();
      date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
      setCookies('iw-cart', userCheckout.id, { expires: date });
    }
    if (myCartId) {
      setCheckoutId(cookies['iw-cart']);
      client.checkout.fetch(myCartId).then((existingCheckout) => {
        setCheckout(existingCheckout);
      });
    } else {
      createCheckout();
    }
    let firstTimeVisit = cookies['iw-first-time-visit-5'];
    if (firstTimeVisit === 'false') {
      setFirstTimeVisitor(false);
    }

  }, []);

  useEffect(() => {
    if (checkout && checkout.completedAt) {
      removeCookies('iw-cart');
    }
  }, [checkout, removeCookies]);

  function handleCheckout(co) {
    setCheckout(co);
  }

  function handleFirstTimeVisit() {
    setFirstTimeOpactity(0);
    let date = new Date();
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    setCookies('iw-first-time-visit-5', "false", { expires: date });
    setTimeout(() => {
      setFirstTimeVisitor(false)
    }, 200);
  }

  if (closed) {
    return (
      <div style={{ opacity: firstTimeOpacity, cursor: 'default' }} className="first-time-container">
        {/* <img style={{ maxWidth: '100%', margin: 'auto' }} src={landingPage} alt="idle-worship-welcome" /> */}
        <video style={{ width: '80%', margin: 'auto' }} loop autoPlay muted playsInline>
          <source src={landingPage} type="video/mp4" />
        </video>
      </div>
    )
  } else if (firstTimeVisitor) {
    return (
      <div onClick={() => handleFirstTimeVisit()} style={{ opacity: firstTimeOpacity }} className="first-time-container">
        {/* <img style={{ maxWidth: '100%', margin: 'auto' }} src={landingPage} alt="idle-worship-welcome" /> */}
        <video style={{ width: '80%', margin: 'auto' }} loop autoPlay muted playsInline>
          <source src={landingPage} type="video/mp4" />
        </video>
      </div>
    );
  } else {
    return (
      <Router>
        <div className="app-container">
          <ScrollToTop />
          <Nav client={client} checkout={checkout} handleCheckout={handleCheckout} />
          <div>
            <Route exact path="/" render={(props) => <ProductList {...props} products={allProducts} />} />
            <Route
              path="/product/:id"
              render={(props) => (
                <ProductDetail {...props} client={client} checkout={checkout} handleCheckout={handleCheckout} />
              )}
            />
            <Route exact path="/archive" render={(props) => <ArchiveList {...props} archive={archive} />} />
            <Route path="/faq" component={FAQ} />
            <Route path="/policies" component={Policies} />
            <Route path="/about" component={About} />
          </div>
        </div>
        <Footer />
      </Router>
    );
  }
}

export default App;
