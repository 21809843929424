import React from 'react';
import './index.css';

function VariantButton({ variant, selected, handleSelected }) {

  if (variant.available === true) {
    return (
      <div onClick={() => {handleSelected(variant.id)}} className={selected ? `variant-container-selected` : `variant-container`}>
        <span className='variant-text'>{variant.title.split(' / ')[0]}</span>
      </div>
    )
  } else {
    return (
      <div style={{ cursor: 'not-allowed' }} className="variant-container">
        <span style={{ color: '#646464', textDecoration: 'line-through'}} className='variant-text'>{variant.title.split(' / ')[0]}</span>
      </div>
    )
  }



}

export default VariantButton;