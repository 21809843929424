import React from 'react';
import './index.css';

function Policies() {
  return (
    <div className="faq-and-policies-container">
      <div className="f-a-p-content">
        <h1>Policies</h1>
        <br />
        Idle Worship has the right to cancel an order at any time. Order cancellations may occur but are not limited to: lack
        of stock to fulfill the order, suspicion of fraud, or any discrepancies on an item's description or price. We will
        contact you if we are unable to fulfill your order based on any of the reasons listed above. If we are unable to
        fulfill any item(s) in your order we will refund you for the item(s).
      </div>
    </div>
  );
}

export default Policies;
