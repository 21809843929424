import React, { useState, useEffect } from 'react';
import VariantButton from '../VariantButton';
import Variants from '../Variants';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './index.css';

function ProductDetail({ match, client, checkout, handleCheckout }) {
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [addToCartText, setAddToCartText] = useState('Add To Bag');
  const [errorText, setErrorText] = useState('');
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (client) {
      client.product.fetchByHandle(match.params.id).then((product) => {
        setProduct(product);
      });
    }
  }, []);

  function renderProductCover() {
    if (product.images.length === 0) { 
      const videoSrc = product.description.split('[/video]')[0].split('[video]')[1];
      return (
        <video style={{ width: '95%', margin: '0 auto' }} loop autoPlay muted playsInline>
          <source src={videoSrc} type="video/mp4" />
        </video>
      )
    } else {
      return (
        <div>
          <img style={{ width: '100%', margin: '0 auto' }} alt="product" src={product.images[0].src} />
        </div>
      )
    }
  }

  function renderProductDescription() {
    let description = product.descriptionHtml.split('[/description]')[0].split('[description]')[1];
    return <div className="description" dangerouslySetInnerHTML={{ __html: description }} />;
  }

  function renderProductDescriptionHTML() {
    let description = product.descriptionHtml.split('[/description]')[1];
    return <div className="description" dangerouslySetInnerHTML={{ __html: description }} />;
  }

  function renderVariant() {
    if (product.variants.length === 1) {
      return <></>;
    } else {
      return (
        <div className="variants">
          <Variants
            variants={product.variants}
            handleSelected={handleSelected}
            currentlySelectedVariant={selectedVariant}
          />
        </div>
      );
    }
  }

  async function addLineItem() {
    setAddToCartText('Adding..');
    const checkoutId = checkout.id;
    const itemToAdd =
      product.variants.length === 1
        ? [{ variantId: product.variants[0].id, quantity: 1 }]
        : [{ variantId: selectedVariant, quantity: 1 }];

    let newCheckout = await client.checkout.addLineItems(checkoutId, itemToAdd);
    handleCheckout(newCheckout);
    setAddToCartText('Added!');
    setTimeout(() => {
      setAddToCartText('Add To Bag');
    }, 1200);
  }

  async function handleAddLineItem() {
    if (product.variants.length === 1) {
      await addLineItem();
    } else if (!selectedVariant) {
      setErrorText('Please select a variant!');
    } else {
      if (selectedVariant) {
        await addLineItem();
      }
    }
  }

  function renderAddToCart() {
    if (product.availableForSale) {
      return (
        <button onClick={() => handleAddLineItem()} className="btn">
          {addToCartText}
        </button>
      );
    } else {
      return <button className="btn-sold-out">SOLD OUT</button>;
    }
  }

  function handleSelected(variantId) {
    setErrorText('');
    setSelectedVariant(variantId);
  }

  if (!product) return <div style={{ height: '70.1vh'}}></div>;

  return (
    <div className="detail-container">
      <div className="details">
        <div className="product-section">
          {renderProductCover()}
          {renderProductDescriptionHTML()}
        </div>
        <div className="info-section">
          <div className="info-section-sticky">
            <div className="info-title">
              {product.title.split('] ')[1]}
              <br />${product.variants[0].price.amount.split('.')[0]}
            </div>
            <div className="info-description">{renderProductDescription()}</div>
            <div className="info-body">
              <div className="separator" />
            </div>
            {renderVariant()}
            <div className="button-container">{renderAddToCart()}</div>
            <div className="detail-error-message">{errorText}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
