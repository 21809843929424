import React from 'react';
import ReactDOM from 'react-dom';
import Client from 'shopify-buy';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const client = Client.buildClient({
  storefrontAccessToken: "fd00488747285c0e10fba4f70928c3d9",
  domain: 'idleworship.myshopify.com'
});

ReactDOM.render(
  <App client={client} />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
