import React from 'react';
import './index.css';

function FAQ() {
  return (
    <div className="faq-and-policies-container">
      <div className="f-a-p-content">
        <h1>FAQ</h1>
        <br />
        <strong>WHY DOESN’T MY DYED TEE SHIRT LOOK EXACTLY LIKE THE PICTURE?</strong>
        <p>Due to the unique nature of dye work, every piece is 1/1 and batches will have slight variation.</p>
        <strong>WILL MY GARMENT BLEED IN THE WASHING MACHINE?</strong>
        <br />
        <p>
          Every garment has been thoroughly rinsed out, but there may be some residual bleeding. Highly suggest to wash
          with dark colors or alone for the first wash.
        </p>
        <br />
        <strong>WILL THE DYES FADE?</strong>
        <br />
        <p>
          All clothes have been pre-treated to minimize fading but like most things, aging is inevitable and beautiful
          at the same time. Nothing is forever.
        </p>
        <br />
        <strong>WHERE IS MY PACKAGE?</strong>
        <br />
        <p>Idle Worship is a small business, we only offer standard shipping through USPS and UPS for now, but as we grow as a company, we plan to offer more shipping options.</p>
        <br />
        <strong>THERE IS AN ISSUE WITH MY ORDER.</strong>
        <br />
        <p>Please contact customer service (<a href="mailto:konoha@idle-worship.com">konoha@idle-worship.com</a>) as soon as possible so we can help resolve the issue. Please note that due to Covid-19 we only accept exchanges & returns for discrepancies made by the shipper (Idle Worship). Please contact us if that applies to your order. Other than any of the issues noted above *<strong>All SALES ARE FINAL</strong>* </p>
        <br />
        <strong>DO YOU TAKE CUSTOM ORDERS?</strong>
        <br />
        {/* <p>
          Yup. I take orders big and small. Hit me at{' '}
          <a href="mailto:hokage@idle-worship.com">hokage@idle-worship.com</a> and we can talk specifics.
        </p> */}
        <p>
          Yup. Idle Worship also functions as a dyehouse for orders big and small. Hit me at <a href="mailto:hokage@idle-worship.com">hokage@idle-worship.com</a> and
          we can talk specifics.
        </p>
      </div>
    </div>
  );
}

export default FAQ;
