import React from 'react';
import { Link } from 'react-router-dom';
import footer from '../../assets/footer.png';
import './index.css';

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div style={{ cursor: 'pointer' }} onClick={() => window.scrollTo(0, 0)}>
          Back To Top
        </div>
        <img src={footer} alt="idle-worship-footer" className="footer-logo" />
        <div className="footer-links">
          <ul>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/policies">Policies</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <a href="https://www.instagram.com/idle.worship/" target="_blank" rel="noopener noreferrer">
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
