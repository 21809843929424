import React from 'react';

function About() {
  return (
    <div className="faq-and-policies-container">
      <div className="f-a-p-content">
        <h1>About</h1>
        <br />
        <span>
          Focused on storytelling through quality goods and 
        </span>
        <br />
        <span>
          uplifting the communities that raised us.
        </span>
      </div>
    </div>
  );
}

export default About;
