import React from 'react';
import './index.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function Image({ product }) {
  const { width } = useWindowDimensions();

  function renderImage() {
    if (width < 880 && product.title.includes('Spacer')) {
      return;
    } 
    
    if (product.title.includes('S]')) {
      if (product.title.includes('Soon')) {
        return (
          <div className="image-container">
            <div className="product-images">
              <img loading="lazy" style={{ width: '100%' }} className="product-image-1" alt="product" src={product.images[0].src} />
              <div className="product-title">Coming Soon</div>
            </div>
          </div>
        )
      } else if (product.images.length === 0) {
        return (
          <div className="archive-item-container archive-video">
            <div className="archive-item-images">
              <video style={{ width: '100%' }} loop autoPlay muted playsInline>
                <source src={product.description} type="video/mp4" />
              </video>
            </div>
          </div>
        );
      } else {
        return (
          <div className="image-container">
              <div className="product-images">
                <img loading="lazy" style={{ width: '100%' }} className="product-image-1" alt="product" src={product.images[0].src + '&width=640'} />
              </div>
          </div>
        );
      }
    } else if (product.title.includes('M]')) {
      if (product.images.length === 0) {
        return (
          <div className="archive-item-container-medium">
            <div className="archive-item-images">
              <video style={{ width: '100%' }} loop autoPlay muted playsInline>
                <source src={product.description} type="video/mp4" />
              </video>
            </div>
          </div>
        );
      } else {
        return (
          <div className="image-container-medium">
              <div className="product-images">
                <img loading="lazy" style={{ width: '100%' }} className="product-image-1" alt="product" src={product.images[0].src} />
              </div>
          </div>
        );
      }
    } else if (product.title.includes('L]')) {
      if (product.images.length === 0) {
        return (
          <div className="archive-item-container-large">
            <div className="archive-item-images">
              <video style={{ width: '99%' }} loop autoPlay muted playsInline>
                <source src={product.description} type="video/mp4" />
              </video>
            </div>
          </div>
        );
      } else {
        return (
          <div className="image-container-large">
              <div className="product-images">
                <img loading="lazy" style={{ width: '99%' }} className="product-image-1" alt="product" src={product.images[0].src} />
              </div>
          </div>
        );
      }
    }
  }

  return (
    <>
      {renderImage()}
    </>
  );
}

export default Image;
