import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/close-icon.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import './index.css';

function Cart({ client, checkout, isCartOpen, handleIsCartOpen, handleCheckout }) {
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);

  function handleUpdate(id, qty, updateType) {
    let newQty = qty;
    if (updateType === '+') {
      newQty++;
    } else {
      newQty--;
    }
    const lineItemToUpdate = { id, quantity: newQty };
    const checkoutId = checkout.id;

    if (newQty === 0) {
      const lineItemIdsToRemove = [id];
      client.checkout.removeLineItems(checkoutId, lineItemIdsToRemove).then(co => {
        handleCheckout(co);
      });
    } else {
      client.checkout.updateLineItems(checkoutId, lineItemToUpdate).then(co => {
        handleCheckout(co);
      });
    }
  }

  function removeLineItem(lineItemId) {
    setIsDeleteDisabled(true);
    const checkoutId = checkout.id;

    const lineItemIdsToRemove = [lineItemId];
    client.checkout.removeLineItems(checkoutId, lineItemIdsToRemove).then(co => {
      handleCheckout(co);
      setIsDeleteDisabled(false);
    });
  }

  function renderCartItems() {
    return checkout.lineItems.map(item => {
      const price = item && item.variant && item.variant.price.amount 
        ? parseInt(item.variant.price.amount) 
        : 0;
      const quantity = item && item.quantity
        ? parseInt(item.quantity)
        : 0;
      const handle = item && item.variant && item.variant.product && item.variant.product.handle 
        ? item.variant.product.handle 
        : '';
      const size = item && item.variant && item.variant.title && (item.variant.title !== 'Default Title')
        ? ` – ${item.variant.title}`
        : '';
      
      return (
        <div key={item.id} className="cart-item">
          {/* <div 
            className="cart-image-container" 
            style={{backgroundImage: `url(${item.variant.image.src})`}}
          /> */}
          <div className="cart-item-info">
            <div className="cart-item-header">
              <div className="cart-item-title">
                {/* <Link to={`/product/${handle}`}> */}
                  {item.title.split('] ')[1]}
                {/* </Link> */}
                <Logo 
                  onClick={() => removeLineItem(item.id)} 
                  className="cart-item-remove"
                  disabled={isDeleteDisabled}
                ></Logo>
              </div>
              <div className="cart-variant">{item.variant.title.includes('Default Title') ? '' : item.variant.title}</div>
            </div>
            <div className="cart-item-footer">
              <div className="cart-item-quantity">
                Qty: {quantity}
              </div>
              <div className="cart-item-price">
                ${price}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  function renderCheckout() {
    if (checkout) {
      if (checkout.lineItems.length === 0) {
        return (
          <div>
            <h1 className="cart-empty">Your cart is empty.</h1>
          </div>
        );
      } else {
        return (
          <>
            <div className="cart-items-container">
              {renderCartItems()}
            </div>
            <div className="cart-footer">
              <div className="footer-contents">
                <div className="subtotal-contents">
                  <div className="subtotal-title">Subtotal</div>
                  <div className="subtotal-value">${parseInt(checkout.subtotalPrice.amount)}</div>
                </div>
                <div className="checkout-button-container">
                  <button onClick={(e) => {
                    e.preventDefault();
                    window.location.href = checkout.webUrl;
                  }} className="checkout-button">Checkout</button>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  }

  return (
    <div className="cart-container" style={{display: isCartOpen ?  '' : 'none'}}>
      <OutsideClickHandler onOutsideClick={() => handleIsCartOpen()}>
        <div className="cart-content">
          {renderCheckout()}
        </div>
      </OutsideClickHandler>
    </div>
  );

}

export default Cart;