import React, { useState } from 'react';
import VariantButton from '../VariantButton';
import './index.css';

function VariantSection({ groupedVariants, handleSelected, currentlySelectedVariant }) {
  const [selectedColor, setSelectedColor] = useState(null);

  function renderColorVariants() {
    return Object.keys(groupedVariants).map((color) => {
      return (

        <div
          style={{ backgroundColor: color.includes(' (') ? (color.split('(')[1]).slice(0, -1) : color }}
          className="variants-color-dot"
          onClick={() => setSelectedColor(color)}
          key={color}
        ></div>
      );
    });
  }

  function renderSizeVariants() {
    if (!selectedColor) {
      return <div></div>;
    } else {
      return groupedVariants[selectedColor].map((variant) => {
        return (
          <div 
            key={variant.variant.id} 
            onClick={() => handleColorAndSizeSelect(variant.variant.id)}
            className={currentlySelectedVariant === variant.variant.id ? 'variants-size-container-selected' : 'variants-size-container'}
          >
            <span className="variants-size-text">{variant.size}</span>
          </div>
        );
      });
    }
  }

  function handleColorAndSizeSelect(id) {
    handleSelected(id);
  }

  return (
    <div className="variants-section">
      <div className="variants-color-section">
        <div className="variants-color-header">
          Select Color
          <span style={{ float: 'right' }}>{selectedColor ? selectedColor : ''}</span>
        </div>
        <div className="variants-colors">{renderColorVariants()}</div>
      </div>
      <div className="variants-size-section">
        <div className="variants-size-header">Select Size</div>
        <div className="variants-sizes">{renderSizeVariants()}</div>
      </div>
    </div>
  );
}

export default VariantSection;
