/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Cart from '../Cart';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/iw-logo.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './index.css';

function Nav({ client, checkout, handleCheckout }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { width } = useWindowDimensions();

  function handleBurger() {
    window.scrollTo(0, 0);
    setIsExpanded(!isExpanded);
  }

  function handleIsCartOpen() {
    setIsCartOpen(!isCartOpen);
  }

  function renderNavItems() {
    if (width > 880) {
      return (
        <div className="nav-links">
          <ul>
            <Link to="/">Shop</Link>
            <Link to="/archive">Archive</Link>
          </ul>
        </div>
      );
    }
  }

  function renderLinks() {
    if (width < 880) {
      return (
        <div className="links-burger">
          <ul className={`nav-mobile-links ${isExpanded ? 'nav-expanded' : ''}`}>
            <Link onClick={() => setIsExpanded(false)} to="/">
              Shop
            </Link>
            <Link onClick={() => setIsExpanded(false)} to="/archive">
              Archive
            </Link>
          </ul>
        </div>
      );
    }
  }

  function renderBag() {
    if (isCartOpen === false) {
      if (width < 880) return <a onClick={() => setIsCartOpen(true)}>{`Bag (${bagQuantity()})`}</a>;
      else return <a onClick={() => setIsCartOpen(true)}>{`Shopping Bag (${bagQuantity()})`}</a>;
    } else {
      if (width < 880) return <span>{`Bag (${bagQuantity()})`}</span>;
      else return <span>{`Shopping Bag (${bagQuantity()})`}</span>;
    }
  }

  function bagQuantity() {
    if (checkout.lineItems.length === 0) {
      return 0;
    } else {
      return checkout.lineItems.map((el) => el.quantity).reduce((acc, curr) => acc + curr);
    }
  }

  if (!checkout) return <></>;

  return (
    <>
      <nav>
        <div className="nav-left">
          <div onClick={() => handleBurger()} className="nav-burger">
            <div />
            <div />
            <div />
          </div>
          <Link className="logo" style={{ textDecoration: 'none' }} to="/">
            <Logo style={{ height: '22px' }} />
          </Link>
          {renderNavItems()}
        </div>
        <div className="nav-links">
          {renderBag()}
          {isCartOpen && (
            <Cart
              client={client}
              checkout={checkout}
              isCartOpen={isCartOpen}
              handleIsCartOpen={handleIsCartOpen}
              handleCheckout={handleCheckout}
            />
          )}
        </div>
      </nav>
      {renderLinks()}
    </>
  );
}

export default Nav;
