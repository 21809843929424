import React from 'react';
import Product from '../Product';
import Image from '../Image';
import './index.css';

function ProductList({ products }) {

  function renderProducts() {
    if (products) {
      return products.map(product => {
        if (product.title.includes('[Image')) {
          return <Image key={product.handle} product={product} />
        } else if (product.title.includes('[Product')) {
          return <Product key={product.handle} product={product} />
        }
        
        return null;
      });
    }
  }

  if (!products) return <div style={{ height: '70.1vh'}}></div>;

  return (
    <div className="product-list-container">
      <div className="product-list">
        {renderProducts()}
      </div>
    </div>
  );

}

export default ProductList;