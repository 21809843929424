import React from 'react';
import ArchiveHeader from '../../assets/Archive.png';
import Archive from '../Archive';
import './index.css';

function ArchiveList({ archive }) {
  function renderArchive() {
    if (archive) {
      // console.log(archive);
      return archive.map((product) => {
          return <Archive key={product.handle} product={product} />;
      });
    }
  }

  return (
    <div className="archive-container">
      <div className="archive-header">
        <img src={ArchiveHeader} alt="archive-header" />
      </div>
      <div className="archive-list-container">
        <div className="archive-list">{renderArchive()}</div>
      </div>
    </div>
  );
}

export default ArchiveList;
