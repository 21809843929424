import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy'
import { Waypoint } from 'react-waypoint'
import { Link } from 'react-router-dom';
import './index.css';

function Product({ product }) {
  const [shouldPlay, updatePlayState] = useState(false);

  function handleEnterViewport() {
    updatePlayState(true);
  }

  function handleExitViewport() {
    updatePlayState(false);
  }

  function renderProduct() {
    if (product.images.length === 0) {
      const videoSrc = product.description.split('[/video]')[0].split('[video]')[1];

      return (
        <Waypoint onEnter={handleEnterViewport} onLeave={handleExitViewport}>
        <Link className="product-link-container" to={`/product/${product.handle}`}>
          <div className="product-container">
            <div
              className="product-images"
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100%' }}
            >
              {/* <video style={{ width: '100%' }} loop autoPlay muted playsInline>
                <source src={videoSrc} type="video/mp4" />
              </video> */}
                <ReactPlayer width='100%' height={'448px'} url={videoSrc} loop playing={shouldPlay} volume={0} muted playsinline />
              <div
                className="product-title"
                style={{ position: 'unset', marginTop: '16px', marginLeft: '16px', textAlign: 'left' }}
              >
                {product.title.split(']')[1]}
              </div>
            </div>
          </div>
        </Link>
        </Waypoint>
      );
    } else {
      if (product.title.includes('S]')) {
        return (
          <Link className="product-link-container" to={`/product/${product.handle}`}>
            <div className="product-container">
              <div className="product-images">
                <img loading="lazy" style={{ width: '100%' }} className="product-image-1" alt="product" src={product.images[0].src} />
                <div className="product-title">{product.title.split(']')[1]}</div>
              </div>
            </div>
          </Link>
        );
      } else if (product.title.includes('M]')) {
        return (
          <Link className="product-link-container" to={`/product/${product.handle}`}>
            <div className="product-container-medium">
              <div className="product-images">
                <img className="product-image-1" alt="product" src={product.images[0].src} />
                <div className="product-title">{product.title.split(']')[1]}</div>
              </div>
            </div>
          </Link>
        );
      } else {
        return (
          <Link className="product-link-container" to={`/product/${product.handle}`}>
            <div className="product-container-large">
              <div className="product-images">
                <img loading="lazy" className="product-image-1" alt="product" src={product.images[0].src} />
                <div className="product-title">{product.title.split(']')[1]}</div>
              </div>
            </div>
          </Link>
        );
      }
    }
  }

  return renderProduct();
}

export default Product;
