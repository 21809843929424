import React from 'react';
import VariantSection from '../VariantSection';
import VariantButton from '../VariantButton';

import './index.css';

function Vartiants({ variants, handleSelected, currentlySelectedVariant }) {
  function renderVariantButtons() {
    if (variants[0].title.includes(' / ')) {
      let groupedVariants = {};

      for (let i = 0; i < variants.length; i++) {
        let [size, color] = variants[i].title.split(' / ');

        if (groupedVariants.hasOwnProperty(color)) {
          groupedVariants[color].push({ size, variant: variants[i] });
        } else {
          groupedVariants[color] = [{ size, variant: variants[i] }];
        }
      }

      return (
        <VariantSection
          currentlySelectedVariant={currentlySelectedVariant}
          groupedVariants={groupedVariants}
          handleSelected={handleSelected}
        />
      );
    } else {
      return (
        <div className="variants-sizes-only">
          {variants.map((variant) => {
              let selected;
              if (currentlySelectedVariant === variant.id) {
                selected = true;
              } else {
                selected = false;
              }
              return (
                <VariantButton key={variant.id} variant={variant} selected={selected} handleSelected={handleSelected} />
              );
          })}
        </div>
      );
    }
  }

  if (!variants) return <div />;

  return <>{renderVariantButtons()}</>;
}

export default Vartiants;
